import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Introduction`}</h1>
    <p>{`This methods guide introduces best practices to elevate your use of Polis from a quirky experiment in a sandbox with your friends to a powerful listening aid that whether deployed inside or outside a formal governance process both depicts and produces the public will. `}</p>
    <h3><strong parentName="h3">{`How this guide is organized:`}</strong></h3>
    <p>{`We open with a provocative question, `}<a parentName="p" {...{
        "href": "https://www.notion.so/Provocation-Is-Polis-too-easy-to-use-396e689617a54250a96e623cdc6b383a"
      }}>{`“Is Polis `}<em parentName="a">{`too`}</em>{` easy to use?”`}</a></p>
    <p>{`Then we walk through some `}<a parentName="p" {...{
        "href": "https://www.notion.so/What-is-Polis-and-what-does-it-do-bf7301b77dbf4fea8210cb9a5d124010"
      }}>{`Polis `}<strong parentName="a">{`basics`}</strong></a>{`: what is it, where has it been `}<a parentName="p" {...{
        "href": "https://www.notion.so/What-has-polis-been-successfully-used-for-00933e23776c4795a11e35faa3bb5df7"
      }}>{`successful`}</a>{`, what it `}<a parentName="p" {...{
        "href": "https://www.notion.so/What-won-t-Polis-do-e99b47c09c224eae983d0e07bdbdc355"
      }}>{`won’t do`}</a>{`.`}</p>
    <p>{`With this in hand, we lay out how you might assess `}<a parentName="p" {...{
        "href": "https://www.notion.so/Me-and-my-use-case-is-Polis-the-right-fit-9c3151d058834608bd043a70eccf2ae5"
      }}>{`if Polis is the right `}<strong parentName="a">{`fit`}</strong></a>{` for your situation.`}</p>
    <p>{`At core of this guide are four areas of practice — `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://www.notion.so/Framing-the-topic-d13b44b73598459a872834e827b1cf06"
        }}>{`framing`}</a>{`, `}<a parentName="strong" {...{
          "href": "https://www.notion.so/Outreach-Distribution-ba6f0bda641548cf8007644f4276e2ef"
        }}>{`outreach`}</a>{`, `}<a parentName="strong" {...{
          "href": "https://www.notion.so/Moderation-832afcc94a03464abe727540f7ace51f"
        }}>{`moderation`}</a>{`, and `}<a parentName="strong" {...{
          "href": "https://www.notion.so/Report-Interpretation-a4c64cc4e6f94b6ba7fadc8f7afd889b"
        }}>{`interpreting results`}</a></strong>{`. We illustrate each with lessons learned from real world applications.`}</p>
    <p>{`Broadening scope a bit, we offer an `}<strong parentName="p">{`orientation`}</strong>{` to the contexts swirling around Polis: open source civic technology, artificial intelligence, communication history & theory, peace building, and others. `}</p>
    <p>{`We include sections on `}<strong parentName="p">{`math`}</strong>{` that explain the assumptions, values, and goals that informed the writing of the algorithm and the way that results are presented in the report. `}</p>
    <p>{`We continue onto `}<strong parentName="p">{`values`}</strong>{` to discuss what guardrails are needed on data science, the danger of engineering false consensus, and why open source is critical for technology used in formal political processes.`}</p>
    <p>{`As an `}<strong parentName="p">{`appendix`}</strong>{`, we include links to Methods Guides created by our partners for their contexts and audiences, then finish with a `}<strong parentName="p">{`glossary`}</strong>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      